// 追加
import '@splidejs/splide/css';
import '../sass/style.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
// addNum関数の読み込みと実行結果のConsole出力
import { App } from './App';
const { gsap } = require("gsap/dist/gsap");
const { ScrollTrigger } = require("gsap/dist/ScrollTrigger");
gsap.registerPlugin(ScrollTrigger);
/**
 * -------------
 * 業務経験 スライダー
 * -------------
 */
/*new Splide('.splide', {
  type: 'loop',
  arrows: false,
  perMove: 1,
  focus: 'center',
  padding: '10%',
  autoplay: true,
  interval: 4000,
  pauseOnHover: true,
  pauseOnFocus: true,
  mediaQuery: 'min',
  breakpoints: {
    1000: {
      padding: '0%',
      perPage: 3,
    },
  },
}).mount();*/
/**
 * -------------
 * topText パララックス
 * -------------
 */
const topText = document.querySelectorAll(".js-para_topText >div");
const topTextPara = [
    [100, -100],
    [275, -250],
    [260, -250],
    [200, -200],
];
topText.forEach(function (value, index, array) {
    gsap.fromTo(value, {
        yPercent: topTextPara[index][0],
    }, {
        yPercent: topTextPara[index][1],
        ease: "none",
        scrollTrigger: {
            trigger: ".js-para_topText",
            start: "top bottom",
            end: "bottom top",
            scrub: 1,
            //markers: true,
        },
    });
});
/**
 * -------------
 * 対応力 パララックス
 * -------------
 */
const topInter = document.querySelectorAll(".js-para_topInter >section")[0];
gsap.fromTo(topInter, {
    yPercent: 10,
}, {
    yPercent: -10,
    ease: "none",
    scrollTrigger: {
        trigger: ".js-para_topInter",
        start: "top bottom",
        end: "top 10%",
        scrub: 1,
        //markers: true,
    },
});
/**
* -------------
* 制作物 パララックス
* -------------
*/
/*const topGit: NodeListOf<Element> = document.querySelectorAll(".js-para_topGit >div");
topGit.forEach((target) => {
  gsap.fromTo(
    target.querySelector("img"),
    {
      y: 0
    },
    {
      y: -30,
      ease: "none",
      scrollTrigger: {
        trigger: target,
        start: "top bottom",
        end: "bottom top",
        scrub: 1,
        markers: true,
      }
    }
  );
});*/
/**
* -------------
* 制作・開発実績 パララックス
* -------------
*/
const topProduct = document.querySelectorAll(".js-para_topProduct")[0];
const topProductCircle = document.querySelectorAll(".js-para_topProduct .circle")[0];
const topProductCircleWrap = document.querySelectorAll(".js-para_topProduct .circleWrap")[0];
gsap.fromTo(topProductCircle, {
    scale: 1
    //x: 0,
}, {
    scale: 1,
    //x: 500,
    scrollTrigger: {
        trigger: topProductCircleWrap,
        start: 'top 10%',
        end: 'bottom 12%',
        //markers: true,
        scrub: 1,
        pin: true,
    }
});
// 状態変化
// -------------
const circleAnime = document.querySelectorAll(".js-anime.circle")[0];
const productItems = document.querySelectorAll(".topProduct__item");
const productItemFirst = document.querySelectorAll(".topProduct__item")[0];
// 現在位置取得
const scrollTop = () => {
    return Math.max(window.scrollY, document.documentElement.scrollTop, document.body.scrollTop);
};
// スクロール処理
const onScroll = () => {
    let position = scrollTop();
    let circlePos = circleAnime.getBoundingClientRect();
    let circlePosY = circlePos.top;
    let itemPosF = productItemFirst.getBoundingClientRect();
    let itemPosYF = itemPosF.top;
    //console.log(window.scrollY, itemPosYF);
    productItems.forEach((item, index) => {
        let itemPos = item.getBoundingClientRect();
        let itemPosY = itemPos.top;
        if (position + window.outerHeight > window.scrollY + itemPosYF) {
            if ((circlePosY > itemPosY - 50) && (circlePosY - itemPosY < 100)) {
                //console.log(circlePosY, itemPosYF);
                productItems[index].classList.add("is-active");
            }
            else {
                productItems[index].classList.remove('is-active');
            }
            ;
        }
        ;
    });
};
document.addEventListener("scroll", onScroll);
/**
 * -------------
 * 自己紹介 パララックス
 * -------------
 */
const topProfile = document.querySelectorAll(".js-para_topProfile >section")[0];
const topProfileH = topProfile.clientHeight;
gsap.fromTo(topProfile, {
    yPercent: 3,
    //height: topProfileH,
}, {
    yPercent: -7,
    //height: topProfileH + 100,
    ease: "none",
    scrollTrigger: {
        trigger: ".js-para_topProfile",
        start: "top bottom",
        //end: "bottom top",
        end: "top 10%",
        scrub: 1,
        //markers: true,
    },
});
/**
 * -------------
 * フッター パララックス
 * -------------
 */
const topFooter = document.querySelectorAll(".js-para_footer >div")[0];
gsap.fromTo(topFooter, {
    yPercent: -10,
}, {
    yPercent: 100,
    ease: "none",
    scrollTrigger: {
        trigger: ".js-para_footer",
        start: "top bottom",
        end: "bottom top",
        //end: "top 10%",
        scrub: .5,
        //markers: true,
    },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(React.createElement(App, null));
